/**
 * Created by ramsys2k on 02.05.2016.
 */
jQuery(document).ready(function ($) {
	//sidenav subtoggle
	jQuery('.sketch_dropdown ul.nav li.parent:not(.active) ul').slideToggle().hide();
	jQuery('.sketch_dropdown ul.nav li.parent').prepend("<span class='toggl0r'></span>");
	jQuery(".sketch_dropdown span.toggl0r").each(function () {
		if (jQuery(this).parent().hasClass("active") == true) {
			jQuery(this).addClass("active");
		}
	});
	jQuery('.sketch_dropdown ul.nav li.parent > span.toggl0r').click(function () {
		jQuery(this).siblings('ul').slideToggle();
		jQuery(this).toggleClass("active");
		return false;
	});
});
